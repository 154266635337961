<template>
  <div>
    <shipblu-card :cardLoading="insightsLoader" :class="accessFinancials ? '' : 'blurred-section'" class="pt-6 relative h-full">
      <p class="font-medium text-primary text-xl px-4 mb-2 whitespace-no-wrap">{{$t('Your Average Shipping Fees')}}</p>
      <p class="font-medium px-4 mb-9">{{$t('Ship more, save more on your shipments with ShipBlu.')}}</p>
      <h3 class="mb-4 font-bold text-2xl text-blue-100 text-center">{{$t('EGP')  + ' '  + Number(insightsData.average_shipping_price).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</h3>
      <p class="whitespace-no-wrap font-bold mb-2 text-center">{{$t('Your Base Price:') + ' ' + insightsData.base_price + ' ' + $t('EGP')}} </p>
      <div class="text-right p-4 lg:absolute lg:mt-0 mt-8 bottom-0 w-full bottom-card rounded-b-md ">
        <p class="cursor-pointer underline font-medium text-blue-100" @click="accessFinancials ? $router.push(`/${$route.params.lang}/merchant/financials?tab=pricing`).catch(()=> {}) : ''">{{$t('Access Price Plan')}}</p>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['insightsData', 'insightsLoader', 'accessFinancials'],
  components: {
    ShipbluCard
  }
}
</script>