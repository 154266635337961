<template>
  <shipblu-card :cardLoading="actionCenterLoader" class="p-4 vs-con-loading__container" id="action-center-loading">
    <div class="flex justify-between mb-2">
      <p class="font-medium text-primary text-xl ml-1">{{$t('Notification Center')}}</p>
      <feather-icon @click="$router.push({name: 'merchant-action-center'}).catch(() => {})" class="cursor-pointer text-grey-dark" icon="ExternalLinkIcon" svgClasses="w-6 h-6"></feather-icon>
    </div>
    <div v-if="data.length === 0" class="not-data-table vs-table--not-data">
      No data Available
    </div>
    <div v-else @scroll="handleScroll" class="overflow-y-auto p-1 h-action relative">
      <shipblu-card v-for="(item, index) in data" :key="item.index" :class="index !== 0 ? 'mt-4' : 'mt-0'">
        <div class="p-6 cursor-pointer" @click="openOrder(item)">
          <p class="font-semibold text-black">
            <span class="text-primary font-medium">{{item.tracking_number}}</span>
            <span class="font-medium">
              {{$t('had an attempt:')}} <span class="text-primary">{{$t(item.comment)}}</span>
            </span>
          </p>
        </div>
      </shipblu-card>
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import { sendRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      disable: false,
      data: [],
      actionCenterLoader: false,
      offset: 0,
      currentPage: 1,
      totalRows: 0,
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE
    }
  },
  methods: {
    openOrder (order) {
      let type = ''
      if (order.tracking_number[0] === '1' || (order.tracking_number[0] === '3' && order.tracking_number[1] === '1')) {
        type = 'delivery-orders'
      }
      if (order.tracking_number[0] === '2' || (order.tracking_number[0] === '3' && order.tracking_number[1] === '2')) {
        type = 'returns'
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-${type}`, 
        params: { type, orderID: order.order_id }
      }).catch(() => {})
    },
    handleScroll (el) {
      if ((el.target.offsetHeight + el.target.scrollTop) >= el.target.scrollHeight && !this.disable) {
        this.loadActionsCenter(true)
      }
    },
    loadActionsCenter (scrollLoader) {
      scrollLoader ? this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#action-center-loading',
        scale: 0.45
      }) : this.actionCenterLoader = true 
      sendRequest(true, false, this, `api/v1/analytics/merchant/dashboard/actions-center/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true, 
        (response) => {
          this.data = [...this.data, ...response.data.results]
          this.disable = response.data.next === null
          this.currentPage++
          this.offset = (this.currentPage - 1) * this.maximumItems
          scrollLoader ? this.$vs.loading.close('#action-center-loading > .con-vs-loading') : this.actionCenterLoader = false
        }
      )
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    this.loadActionsCenter(false)
  }
}
</script>

<style>
.h-action {
  height: 270px;
}
</style>