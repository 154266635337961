<template>
  <shipblu-card class="p-4">
    <vs-tabs v-model="activeTab" class="tabs-shadow-none" id="profile-tabs">
      <vs-tab :label="$t('Scheduled Pickups')">
        <dashboard-orders :activeTab="activeTab"/>
      </vs-tab>
      <vs-tab :label="$t('Scheduled Returns')">
        <dashboard-orders :activeTab="activeTab"/>
      </vs-tab>
    </vs-tabs>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'
import DashboardOrders from './DashboardOrders.vue'

export default {
  data () {
    return {
      activeTab: 0
    }
  },
  methods: {
  },
  components: {
    ShipbluCard,
    DashboardOrders
  }
}
</script>