<template>
  <div>
    <print-label-button class="inline-block" :selected="selected"></print-label-button>
    <shipblu-table 
      :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="orders"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Info')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Phone')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('CoD')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data"
        :to="{ name: 'merchant-delivery-orders', params: { type: `${activeTab === 0 ? 'delivery-orders' : 'returns'}`, orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].trackingNumber" class="inactive-link">
            <p v-on:click.stop>{{ data[indextr].tracking_number }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].customer.address.zone.name) }} / {{ $t(data[indextr].customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone">
            {{ data[indextr].customer.phone }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].packages">
            {{ data[indextr].packages.length }}
          </shipblu-td>
          <shipblu-td :data="data[indextr].cashOnDelivery">
            {{ parseInt(data[indextr].cash_amount) > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'N/A' }}
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import PrintLabelButton from '../../headOfFleet/components/PrintLabelButton.vue'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'

export default {
  props: ['activeTab'],
  data () {
    return {
      selected: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      offset: 0,
      searchVal: this.$route.query.search ? this.$route.query.search : '',
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      orders: []
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadOrders()
    }
  },
  methods: {
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadOrders()
    },
    loadOrders () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      sendRequest(true, false, this, `api/v1/analytics/merchant/dashboard/${this.activeTab === 0 ? 'scheduled-pickups' : 'scheduled-returns'}/${query}`, 'get', null, true, 
        (response) => {
          let count = 1
          this.searchVal === '' ? count = response.data.count : count = 1
          this.orders = response.data.results
          this.pickupsFlag = count > 0 
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    PrintLabelButton,
    ShipbluPagination
  },
  created () {
    this.loadOrders()
  }
}
</script>

<style lang="scss">
.con-pagination-table{
  display: none;
}
</style>
