<template>
  <shipblu-card class="p-4 flex sm:flex-row flex-col sm:items-center gap-y-2 justify-between">
    <div class="relative sm:order-1 order-2">
      <div class="lg:absolute inset-0 flex lg:flex-row flex-col gap-x-2 gap-y-4 xl:px-10 lg:p-6">
        <div class="w-full">
          <p class="text-primary font-semibold md:text-xl text-lg">{{'Delivering Growth'}}</p>
          <p class="font-bold md:text-4xl sm:text-2xl text-xl text-grey-dark md:max-w-sm">{{$t('A Look Back at Your')}} <span class="text-primary">2023</span> {{$t('Shipping Success.')}}</p>
        </div>
        <div class="lg:self-end flex whitespace-no-wrap">
          <div @click="goToReviewPage()" class="cursor-pointer select-none sm:w-auto w-full justify-center flex lg:ml-auto items-center gap-2 bg-primary px-6 py-2 rounded-lg text-white">
            <p class="font-semibold sm:text-xl text-lg">{{'Access Report'}}</p>
            <p class="material-symbols-outlined">{{$i18n.locale === 'en' ? 'arrow_circle_right' : 'arrow_circle_left'}}</p>
          </div>
        </div>
      </div>
      <img height="286" class="max-w-full lg:block hidden filp-image" src="../../../assets/images/pages/year-in-review/growth-banner-bg.svg" alt="">
    </div>
    <div class="text-center sm:order-2 order-1">
      <img class="xl:w-full md:w-76 w-44" src="../../../assets/images/pages/year-in-review/growth-img.svg" alt="">
    </div>
  </shipblu-card>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  methods: {
    goToReviewPage () {
      this.$router.push({
        name: 'merchant-year-in-review'
      }).catch(() => {})
    }
  },
  components: {
    ShipbluCard
  }
}
</script>

<style>
[dir=rtl] .filp-image {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>