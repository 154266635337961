<template>
  <div>
    <div class="first-step">
      <greeting :userMerchant="userMerchant" :user="user" :tour="tour" :tourClosed="tourClosed" :insightsLoader="insightsLoader" :insightsData="insightsData"></greeting>
      <div class="mt-4 grid lg:grid-cols-3 grid-cols-1 gap-4">
        <action-center class="lg:col-span-2 col-span-1"></action-center>
        <insights :insightsLoader="insightsLoader" :insightsData="insightsData" class="col-span-1"></insights>
      </div>
      <div class="mt-4 grid lg:grid-cols-3 grid-cols-1 gap-4">
        <financial-summary :insightsData="insightsData" :accessFinancials="accessFinancials" class="lg:col-span-2 col-span-1"></financial-summary>
        <average-fees :accessFinancials="accessFinancials" :insightsData="insightsData" :insightsLoader="insightsLoader"  class="col-span-1"></average-fees>
      </div>
    </div>
    <scheduled-orders class="mt-4"></scheduled-orders>
  </div>
</template>

<script>
import Greeting from './dashboard/Greeting.vue'
import ActionCenter from './dashboard/ActionCenter.vue'
import FinancialSummary from './dashboard/FinancialSummary.vue'
import Insights from './dashboard/Insights.vue'
import DeliveringGrowth from './dashboard/DeliveringGrowth.vue'
import ScheduledOrders from './dashboard/ScheduledOrders.vue'
import { sendRequest } from '../../http/axios/requestHelper'
import common  from '@/assets/utils/common'
import Shepherd from 'shepherd.js'
import 'shepherd.js/dist/css/shepherd.css'
import AverageFees from './dashboard/AverageFees.vue'

export default {
  data () {
    return {
      userMerchant: {},
      insightsLoader: false,
      insightsData: {
        average_shipping_price: 0
      },
      tour:'',
      tourClosed: true,
      user: {},
      accessFinancials: false
    }
  },
  methods:{
    addTourStep (element) {
      if (element.hideNext) {
        this.tour.addStep({
          text: element.text,
          attachTo: {
            element: element.element,
            on: 'bottom'
          },
          buttons: [
            {
              action: async () => {
                if (element.next) {
                  this.tour.back()
                } else {
                  this.tourClosed = false
                  this.tour.cancel()
                }
              },
              classes: 'shepherd-button-secondary',
              text: element.button1
            }
          ]
        })
      } else {
        this.tour.addStep({
          text: element.text,
          attachTo: {
            element: element.element,
            on: 'bottom'
          },
          buttons: [
            {
              action: async () => {
                if (element.next) {
                  this.tour.back()
                } else {
                  this.tourClosed = false
                  this.tour.cancel()
                }
              },
              classes: 'shepherd-button-secondary',
              text: element.button1
            },
            {
              action: async () => {
                this.tour.next()
              },
              text: element.button2
            }
          ]
        })
      }
    },
    loadInsights () {
      this.insightsLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/insights/', 'get', null, true, 
        (response) => {
          if (this.$cookies.get('tourMerchantDashboard') === 'false' && !(this.user.is_self_signup && this.user.self_signup_verified === null && this.user.name === 'Entrepreneur Merchant')) {
            this.tour.start()
            this.$cookies.set('tourMerchantDashboard', true, '100y')
          }
          this.insightsData = response.data
          this.insightsLoader = false
        }
      )
    }
  },
  components: {
    Greeting,
    ActionCenter,
    FinancialSummary,
    ScheduledOrders,
    Insights,
    DeliveringGrowth,
    Shepherd,
    AverageFees
  },

  created () {
    const merchantData = common.checkMerchantInLocalStorage(this)
    let isHaveFinanceAccess = ''
    merchantData.then(results => {
      this.accessFinancials = results.userMerchantData.access_financials
      this.userMerchant = results.userMerchantData
      this.user = results.merchantData
      isHaveFinanceAccess = this.userMerchant.access_financials
      this.merchantID = this.user.id
      if (this.$cookies.get('tourMerchantDashboard') === 'false') {
        this.tour = new Shepherd.Tour({
          useModalOverlay: true,
          defaultStepOptions: {
            exitOnEsc: true,
            classes: 'shadow-md bg-purple-dark',
            scrollTo: {behavior: 'smooth', block: 'center'}
          }
        })
        const setpsArray = [
          {
            text: this.$t('Your dashboard gives you quick insights about your logistics needs.'),
            element: '.first-step',
            button1: this.$t('Cancel'),
            button2: this.$t('Next'),
            next: false
          },
          {
            text: this.$t('Click here to create a new delivery order.'),
            element: '.step-selector',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true,
            hideNext: true
          },
          {
            text: this.$t('Click here to create a new delivery order.'),
            element: '.step-selector-2',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true,
            hideNext: true
          },
          {
            text: this.$t('Now, add customer details below'),
            element: '.step-selector-3',
            button1: this.$t('Cancel'),
            button2: this.$t('Next'),
            next: false
          },
          {
            text: this.$t('Great! Now, select the number of packages and optionally add description and cash on delivery amount.'),
            element: '.step-selector-4',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true
          },
          {
            text: this.$t('Optionally, add a reference number for the order and leave any note for our delivery agent.'),
            element: '.step-selector-5',
            button1: this.$t('Back'),
            button2: this.$t('Next'),
            next: true
          },
          {
            text: this.$t('Your are now one-step ahead from creating your first order. Click Create Order now!'),
            element: '.step-selector-6',
            button1: this.$t('Back'),
            button2: this.$t('Done!'),
            next: true,
            hideNext: true
          }
        ]
        if (!isHaveFinanceAccess) setpsArray.splice(9, 1)
        setpsArray.forEach((element) => { 
          this.addTourStep(element)
        })
      }
      this.loadInsights()
    })
  }
}
</script>

<style lang="scss">
.shepherd-enabled.shepherd-element {
  z-index: 51900;
}
.shepherd-modal-is-visible{
  z-index: 51900;
}
.shepherd-text {
  padding: 0 2em;
}
.shepherd-header {
  padding: 0 0.75rem;
  height: 35px;
}
.shepherd-text{
  padding: 1rem 0.5rem 0rem 0.5rem;
}
.shepherd-footer{
  margin-top: 16px;
}
.shepherd-button {
  background: #1C5BFE;
}
.shepherd-arrow:before {
  background: #f3f3f3;
}
.shepherd-element{
  background: #f3f3f3;
}
.shepherd-button {
  color: #fff; 
}
[dir] .shepherd-button.shepherd-button-secondary {
  background: #fff;
}
.bottom-card {
  background-color: #ECF3FB;
}
.blurred-section {
  cursor: not-allowed;
  filter: blur(5px);
}
</style>