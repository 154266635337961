<template>
  <div>
    <shipblu-card :cardLoading="insightsLoader" class="pt-4 h-full relative">
      <p class="font-medium text-primary text-xl px-4 mb-3">{{$t('Delivery success rate')}}</p>
      <div class="graph-container m-auto">
        <progress :class="$i18n.locale === 'ar' ? 'rotate-270' : 'rotate-90'" class="progress-circle" :value="`${insightsData.delivery_success_rate}`" max="100" :style="`--progress:${insightsData.delivery_success_rate}`"></progress>
      </div>
      <div class="flex gap-4 lg:absolute lg:mt-0 mt-8 bottom-0 w-full items-center px-6 py-3 rounded-b-md bottom-card">
        <img src="@/assets/images/pages/prize.svg" width="30" alt="" />
        <p class="font-bold text-black text-lg">{{$t('1st Attempt Delivery Rate:')}}</p>
        <p class="font-bold text-blue-100 text-xl">{{ insightsData.delivery_success_rate_1st_attempt + '%' }}</p>
      </div>
    </shipblu-card>
  </div>
</template>

<script>
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['insightsData', 'insightsLoader'],
  data () {
    return {
      activeSlide: 0,
      successRate: 0,
      list: [
        {
          text: 'Your orders got delivered successfully? Find out how to add a <b>payout method</b> to receive the collected cash by following ',
          link: 'https://shipblu.freshdesk.com/en/support/solutions/articles/80001046665-how-can-i-add-a-new-payment-method-',
          id: 1
        },
        {
          text: 'Looking for enhanced protection for your shipments? Explore <b>ShipBlu Shield</b> Plans by following ',
          link: 'https://shipblu.freshdesk.com/en/support/solutions/articles/80001052975-shipblu-shield-plans-terms-conditions',
          id: 2
        },
        {
          text: 'Seeking a seamless delivery experience for your shipments? Discover our <b>packaging guidelines</b> by following ',
          link: 'https://shipblu.freshdesk.com/en/support/solutions/articles/80001044109-packaging-guidelines',
          id: 3
        },
        {
          text: 'We are making efforts in expanding our network of covered zones. Stay updated on the current list of <b>uncovered zones</b> and areas by following ',
          link: 'https://shipblu.freshdesk.com/en/support/solutions/articles/80001077339-uncovered-zones-areas',
          id: 4
        }
      ]
    }
  },
  components: {
    ShipbluCard
  },
  created () {
    setInterval(function () {
      this.activeSlide = this.activeSlide < this.list.length - 1 ? this.activeSlide + 1 : 0
    }.bind(this), 7000)
  }
}
</script>

<style lang="scss">
.graph-container {
  width: 200px;
  height: 200px;
  ::-webkit-progress-inner-element, ::-webkit-progress-bar, ::-webkit-progress-value {
    display: none;
  }
  ::-moz-progress-bar {
    background: transparent;
  }
  .progress-circle {
    &.rotate-90 {
      transform: rotate(-90deg);
      &::after {
        content: attr(value) "%";
        transform: rotate(90deg);
      }
    }
    &.rotate-270 {
      transform: rotate(-270deg);
      &::after {
        content: attr(value) "%";
        transform: rotate(270deg);
      }
    }
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    --fill: calc(var(--progress) * 1%);
    background: conic-gradient(#1c5bfe var(--fill), lightgrey 0);
    &::after {
      position: absolute;
      inset: 25px;
      background: white;
      border-radius: 50%;
      color: #1c5bfe;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
    }
  }
}
.insight-card {
  @media (max-width: 991px) {
    height: 100%;
  }
  @media (max-width: 767px) {
    height: calc(100% - 182px);
  }
  @media (max-width: 575px) {
    height: 175px;
  }
  height: calc(100% - 182px);
}
.silder-dot {
  border-radius: 100px;
  background-color: gray;
  width: 9px;
  height: 9px;
}
.slide-in {
  -webkit-animation: linear;
  -webkit-animation-name: run;
  -webkit-animation-duration: 0.3s;
}
@-webkit-keyframes run {
  0% { left: 100%; right: 100%; }
  100% { left: 0; right: 0; }
}
</style>