<template>
  <div>
    <div class="flex items-center justify-between">
      <h2>
        <span>{{$t('Hello')}} </span>
        <span class="text-primary">{{userMerchant.first_name}}</span>,
      </h2>
      <shipment :user="user" :tour="tour" :tourClosed="tourClosed" @tour="tour = $event"></shipment>
    </div>
    <div class="sm:text-xl text-base mb-5 font-medium">
      <span class="text-black">{{$t('Thank you for choosing') + ' '}}</span>
      <span class="text-blue-100">{{$t('Ship')}}</span>
      <span class="text-black">{{$t('Blu') + ' '}}</span>
      <span class="text-black">{{$t('as your eCommerce Shipping partner.')}}</span>
    </div>
  </div>
</template>

<script>
import Shipment from '../components/Shipment.vue'
import ShipbluCard from '@/layouts/components/ShipbluCard.vue'

export default {
  props: ['insightsData', 'insightsLoader', 'tour', 'tourClosed', 'userMerchant', 'user'],
  data () {
    return {
    }
  },
  methods: {
  },
  components: {
    Shipment,
    ShipbluCard
  }
}
</script>
